<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-vendor
            name="vendorCd"
            label="수급업체"
            v-model="searchParam.vendorCd"
          ></c-vendor>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="VENDOR_EVALUATION_KIND_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="vendorEvaluationKindCd"
            label="평가구분"
            v-model="searchParam.vendorEvaluationKindCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="EVALUATION_GRADE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="evaluationGradeCd"
            label="평가등급"
            v-model="searchParam.evaluationGradeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="평가기간"
            name="evalTerms"
            v-model="searchParam.evalTerms"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-construnction
            type="search"
            name="sopConstructionId"
            v-model="searchParam.sopConstructionId">
          </c-construnction>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="수급업체 평가 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="등록" v-if="editable" icon="add" @btnClicked="linkClick()" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'vendor-assess',
  data() {
    return {
      searchParam: {
        plantCd: null,
        vendorCd: null,
        vendorEvaluationKindCd: null,
        evaluationGradeCd: null,
        evalTerms: [],
        sopConstructionId: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '수급업체',
            style: 'width:200px',
            align: 'left',
            type: 'link',
            sortable: true,
          },
          {
            name: 'vendorEvaluationKindName',
            field: 'vendorEvaluationKindName',
            label: '평가구분',
            style: 'width:140px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '상태',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            label: '평가점수',
            child: [
              {
                name: 'totalScore',
                field: 'totalScore',
                style: 'width:80px',
                label: '총점',
                align: 'center',
                sortable: true,
              },
              {
                name: 'score',
                field: 'score',
                style: 'width:80px',
                label: '평점',
                align: 'center',
                sortable: true,
              },
              {
                name: 'evaluationGradeName',
                field: 'evaluationGradeName',
                style: 'width:80px',
                label: '등급',
                align: 'center',
                sortable: true,
              },
            ]
          },
          {
            name: 'evaluationDate',
            field: 'evaluationDate',
            label: '평가기간',
            style: 'width:180px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '평가결과 종합',
            style: 'width:280px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'constructionName',
            field: 'constructionName',
            style: 'width:180px',
            label: '공사',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        isFull: true,
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.cim.vendor.evaluation.result.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = "수급업체 평가 상세"; 
      this.popupOptions.param = {
        mdmVendorEvaluationId: row ? row.mdmVendorEvaluationId : '',
        vendorCd: row ? row.vendorCd : '',
      };
      this.popupOptions.target = () => import(`${"./vendorAssessDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
